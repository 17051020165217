.c-header {
  border-bottom: 1px solid $clr-secondary;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1300px) {
    height: 60px;
    max-height: 60px;
  }

  &__logo {
    display: flex;

    img {
      max-width: 20rem;
      max-height: 5rem;

      @media screen and (max-width: 1300px) {
        max-width: 18rem;
      }

      @media screen and (max-width: 450px) {
        max-width: 13rem;
        max-height: 4rem;
      }
    }

  }

  &__nav {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1300px) {
      display: none;
    }

    li {
      margin: 0 1rem;
      a {
        font-size: 1.7rem;
        font-weight: 600;
      }
    }
  }
  &__misc {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1300px) {
      display: none;
    }

    button {
      margin-left: .5rem;

      i {
        font-size: 2rem;
      }
    }
    .btn--outline {
      span, i {
        color: $color-black;
      }
    }
    .btn--square {
      span, i, i:before {
      }
    }
  }

  &__menu-trigger {
    width: 35px;
    height: 40px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1300px) {
      display: flex;
    }

    span {
      width: 100%;
      height: 4px;
      background-color: $clr-primary;

      &:nth-of-type(2) {
        margin: 4px 0;
      }
    }
  }

}
