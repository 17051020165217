.p-activity-participants {
  margin-bottom: 5rem;


  &__date {
    margin-bottom: 1rem;

    span {
      font-size: 1.5rem;
    }

  }

  &__info {
    display: flex;
    align-items: center;

    i {
      margin-right: 0.5rem;
    }

    &__amount {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      margin-right: 2rem;
    }

    &__location {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
    }

  }

  &__list {

    &__header {
      margin: 2rem 0 1rem 4rem;
    }

    &__participants {

      &__ul {
        margin-bottom: 2rem;
      }

      &__participant {
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        border-radius: 1rem;
        background-color: #eee;
        margin-bottom: 1rem;

        &__name {
          flex: 1;
        }

      }

    }

  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;

    .action-btn {
      color: #fff;
      font-weight: bold;
      background-color: rgb(239, 128, 9);
      padding: 1rem 2rem;
      text-align: center;

      &__back {
        margin-left: 4rem;
      }
    }
  }

}