.c-auth {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__logo {
    max-width: 20rem;
    max-height: 8rem;
  }

  &__intro {
    width: 50rem;
    margin: 0 auto;

    @media screen and (max-width: 650px) {
      width: calc(100% - 100px);
    }

    @media screen and (max-width: 450px) {
      width: calc(100% - 50px);
    }

    @media screen and (max-width: 350px) {
      width: calc(100% - 40px);
    }

    h1 {
      font-size: 2.5rem;
      margin-top: 1.5rem;
      margin-bottom: .5rem;
    }
    span {
      font-weight: 600;
      display: block;
    }
    i {
      display: block;
      font-size: 3rem;
    }
    p {
      margin-top: 1.5rem;
      font-size: 1.6rem;
    }
  }

  &__form {
    width: 50rem;
    margin: 3rem auto 0;
    background-color: $clr-secondary;
    padding: 2rem 3rem 3rem 3rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 650px) {
      width: calc(100% - 100px);
      padding: 1.5rem 2.5rem 2.5rem 2.5rem;
    }

    @media screen and (max-width: 450px) {
      width: calc(100% - 50px);
    }

    @media screen and (max-width: 350px) {
      width: calc(100% - 40px);
    }
    small {
      margin-bottom: 2rem;
      font-size: 2rem;
      font-weight: 600;
    }
    a {
      text-decoration: underline;
      font-size: 1.6rem;
    }
  }
}
