// bower:scss
// endbower
@import '0.defaults/defaults';
@import '1.objects/objects';
@import '2.components/components';
@import '3.utilities/utilities';
@import '4.themes/themes';
@import '5.scope/scope';
@import '6.is-has/is-has';
@import '7.hacks/hacks';

.c-reset-time {
  background: palegreen;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: .5rem 1rem;
}

.autosize-wrapper {
  height: 100%;
}


.ReactVirtualized__Table__headerRow {
  background: #272727;
}

.ReactVirtualized__Table__headerColumn {

  span {
    color: #fff;
    font-weight: normal;
    line-height: 1;
    font-size: 1.4rem;
  }
}

.ReactVirtualized__Grid {
  background: #eee;
}

.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
  margin-right: 2rem !important;
}

.ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 2rem !important;
}

.ReactVirtualized__Table__row {
  font-size: 1.3rem;
  transition: all ease-in-out .2s;

  &:hover {
    cursor: pointer;
  }

  &:nth-child(2n -1) {
    background: white;

    &:hover {
      background: #272727;
      color: #fff;
    }
  }

  &:nth-child(2n) {
    background: #eee;

    &:hover {
      background: #272727;
      color: #fff;
    }
  }
}


.autosize-holder {
  > * {
    height: auto !important;
  }
}

body {
  min-height: calc(100vh + 7.3rem);
}

.c-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.c-totals {
  &__options {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__half {
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;

      > div {
        margin-bottom: 1rem !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }

      .c-form__row {
        margin: 0;
      }

      .c-form__group {
        label {
          white-space: nowrap;
          margin-right: 1rem;
          font-size: 1.6rem;
        }
      }

      &:first-of-type {
        .c-totals__sum {
          span:first-of-type {
            margin-right: 2rem;
          }
        }
      }

      &:last-of-type {
        .c-totals__sum {
          span:first-of-type {
            margin-right: 2rem;
          }
        }
      }
    }
  }

  &__sum {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 1.6rem;
    }
  }
}

.c-table-standard {
  width: 100%;
  min-width: 75rem;

  td {
    font-size: 1.6rem;
  }

  tr:first-of-type {

    td {
      font-weight: 600;
    }
  }

  tr {
    border-bottom: 1px solid $gray-new;
  }

  &--prices {
    margin-bottom: 1rem;

    tr {
      td:last-of-type {
        text-align: right;
      }
    }
  }
}

.c-Rental-overview {
  padding: 3rem;
  width: 100%;
  overflow-x: auto;

  &.c-table {
    padding: 0;
  }
}


.c-Rental-overview > * {
  height: auto !important;
}

.pagination {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: flex-end;
  margin-top: 4rem;

  li {
    a {
      font-size: 1.4rem;
      padding: 0.4rem 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    &.active {


      a {
        padding: 0.4rem 1rem;
        background: red;
        color: #fff;
      }
    }
  }

  .previous, .next {
    a {
      font-size: 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    a[aria-disabled=true] {
      opacity: .3;
    }
  }

}

.c-header__logo:hover {
  cursor: pointer;
}

.c-address-item {
  margin: 2rem 0;
  border-top: 1px solid $gray-new;
  padding-top: 1.5rem;
}

.c-loader {
  img {
    max-width: 20rem;
    max-height: 20rem;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  display: block;
}

.c-select-customers {
  &__item {
    padding: 1.5rem 2rem;
    font-size: 1.4rem;
    border-bottom: 1px solid #eee;

    &:hover {
      cursor: pointer;
      background: #eee;
    }
  }
}

.c-add-more {
  color: dodgerblue;
  font-size: 1.2rem;

  &:hover {
    cursor: pointer;
  }
}

.c-info-blocks__text {
  path {
    fill: #fff !important;
  }
}

.s-order-actions > * {
  margin-left: 1rem;
}

.enable-hover {
  cursor: pointer;

  &:hover {
    background: #eee;
  }
}

.c-info-blocks__single__body__row a {
  text-decoration: underline;
  font-weight: bold;
}

.c-current-settings {
  list-style-type: none;
  padding: 0;
  margin-bottom: 3rem;

  ul {
    padding: 0;
    list-style-type: none;

    li {
      font-size: 1.6rem;

      span {
        font-size: 1.6rem;
      }
    }
  }
}


.custom-row {
  display: flex;
  justify-content: space-between;

  > * {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &-1 {
    > * {
      &:nth-child(2) {
        width: 40rem;
      }
    }
  }

  &-2 {
    > * {

      &:nth-child(1) {
        width: 40rem;
      }

      &:last-child {

      }
    }
  }

}

.custom-row-1 {
  &:last-child {
    max-width: 4rem;
  }
}

.custom-location {
  width: 100%;
  border: 1px solid hsl(0, 0%, 80%) !important;
  margin-top: 1rem;
  padding: .7rem 1.4rem;
  border-radius: .8rem;

  &-select {
    padding: 0;
    border: 0 !important;

  }
}

.c-partial-amount {
  display: flex !important;
  align-items: center !important;
  margin-top: 1.5rem;

  p {
    margin-bottom: 0;
    margin-right: 2rem;
  }

  input {
    width: 20rem;
  }
}

.c-info-blocks__single__body {
  font-size: 1.6rem;
}

.btn {
  transition: all ease-in-out .3s;

  &.is-mailing {
    opacity: .2;
    pointer-events: none;
  }

  &.is-completed {
    background: mediumseagreen;
    opacity: .5;
    pointer-events: none;

    span {
      color: #fff !important;
    }
  }
}

.ReactVirtualized__Table__headerRow span {
  font-family: 'Poppins', serif;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  margin-top: .6rem !important;
}

.c-icon-blocks__single div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-search-box {
  transition: all ease-in-out .1s;
  margin-top: 0;

  &.is-hidden {
    margin-top: -6.4rem
  }
}

.tiny-loader {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.c-icon-blocks__single {
  &.is-disabled {
    opacity: .2;
    pointer-events: none;
  }
}


.discount-row {
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 0;
    margin-left: 1rem;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}

.c-order-handling__scanned {
  .icon-check {
    &:before {
      color: #5CAC5C !important;
    }
  }

  .icon-info {
    &:before {
      color: #FF7700 !important;
    }
  }
}

.c-succes-popup {
  position: fixed;
  left: 3rem;
  bottom: 3rem;
  box-shadow: 5px 10px 10px rgb(0 0 0 / 10%);
  max-width: 60rem;
  border-radius: 0.3rem;
  overflow: hidden;
  transition: all ease-in-out 0.5s;
  z-index: 9999;
  background: #fff;

  header {
    background: #edfbf6;
    padding: 1rem 2rem;
    color: #32d296;
    font-size: 1.5rem;
  }

  p {
    font-size: 1.5rem;
    padding: 2rem;
  }
}

.c-error-display {
  margin-top: 2rem;
  padding: 2rem 0;
  border-top: 2px solid #ff827b;
  border-bottom: 2px solid #ff827b;

  h3 {
    font-size: 1.3rem;
  }

  ul {
    li {
      font-size: 1.3rem;
    }

    margin-bottom: 0;
  }
}

.c-no-scanned-items {
  font-size: 1.3rem;
  padding: 1rem 2rem;
  background: #eee;
}

.is-locked {
  opacity: .3;
}

.c-order-handling__number {
  .icon-cross {
    &:before {
      color: white;
    }
  }
}


.c-order-handling-issue-numbers {
  display: flex;
  justify-content: flex-end;

  min-width: 11rem;

  .c-order-handling__list__single__amount {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    justify-content: center;

    button {
      font-size: .5rem;
      border: 0;
      background: #002BD3;
      color: #fff;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 .5rem;
      border-radius: .4rem;

      &.is-disabled {
        opacity: .3;
        pointer-events: none;
      }

      .icon-minus {
        font-size: 3px;

        &:before {
          color: #fff;
        }
      }

      .icon-plus {
        font-size: 9px;

        &:before {
          color: #fff;
        }
      }
    }
  }


}

.c-button-icon {
  &:hover {
    cursor: pointer
  }
}

.c-error-popup {
  position: fixed;
  left: 3rem;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, .1);
  max-width: 60rem;
  border-radius: .3rem;
  overflow: hidden;
  transition: all ease-in-out .5s;
  bottom: -20rem;
  z-index: 9999;
  background: #fff;


  &.is-active {
    bottom: 4rem;
  }

  header {
    background: #ff8888;
    padding: 1rem 2rem;
    color: #fff;
    font-size: 1.5rem;
  }


  p {
    font-size: 1.5rem;
    padding: 2rem;
  }

}

.c-button-icon {
  margin-left: 1.5rem;
}

.c-bicycle-name {
  font-size: 1rem;
}

.c-availability {

  font-size: 1rem;

  th {
    padding: 1rem 0;
    font-size: 1rem;
  }
}

.c-custom-input {
  display: flex;
  align-items: center;

  input {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    margin-left: 2rem;
    border: 1px solid #eee;
    background: #f9f9f9;

    @media screen and (max-width: 800px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  button {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: .6rem;
    margin-left: -1rem;
    border: 0;

  }
}

.c-form {
  button.is-disabled {
    opacity: .4;
    pointer-events: none;
  }
}

.j-a {
  display: flex;
  align-items: center;
}

.c-order-handling__header {
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.issue-create {
  .c-custom-input {

    margin-bottom: 1rem;

    input {
      margin-left: 0;
    }

    button {
      line-height: 2rem;
    }

    i {
      font-size: 1rem;
    }
  }
}

.c-table-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: flex-start !important;

    ul {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        width: calc(50% - 1px);
        margin-right: 0 !important;

        &:nth-of-type(odd) {
          margin-right: 2px !important;
        }
      }
    }

    .u-float-right {
      margin-bottom: 5px;
    }
  }

  select {
  }
}

.ReactVirtualized__Table__headerColumn.ReactVirtualized__Table__sortableHeaderColumn {
  outline: none !important;
}

.ReactVirtualized__Table__sortableHeaderIcon path:last-child {
  fill: none !important;
}

.pdf-download {
  font-weight: 600;
  font-size: 1.4rem;
}

.c-availability {
  tr:nth-child(2n) {
    background: #eee;
  }

  td, th {
    padding: 0 2rem;
    border: 2px solid #FFF;
    text-align: center;
  }
}

.u-float-right {
  .rs-picker-default {
    margin-right: 5rem;
  }
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000;
}

.c-location-alert {
  width: 100%;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, .1);
  border-radius: .3rem;
  right: 2rem;
  padding: 2rem 2rem;
  text-align: center;
}


.c-order-handling-header.is-collapsable {

  i {
    margin-left: 2rem;
    font-size: 1rem;
  }

  &:hover {
    cursor: pointer;


  }
}

.transaction-overview {
  th {
    font-size: 1rem;
    padding: 0 1rem;
  }

  td {
    font-size: 1rem;
    padding: 0 1rem;
  }

  tr {


    &:nth-child(2n -1) {
      background: white;

      &:hover {
        background: #272727;
        color: #fff;
      }
    }

    &:nth-child(2n) {
      background: #eee;


    }
  }

  &--hover {

    tr:hover {
      background: #272727;
      color: #fff;
      cursor: pointer;
    }

  }
}

.rs-picker-toggle-clean {
  top: .8rem !important;
}

.rs-picker-toggle-value {
  font-size: 1.5rem;
}

.rs-picker-daterange {
  margin-right: 1rem !important;
}

.rs-picker-daterange .rs-picker-toggle-caret::before, .rs-picker-toggle-clean {
  font-size: 1.3rem;
}

.rs-picker-daterange .rs-picker-toggle-caret {
  top: .1rem !important;
}

.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn, .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding: .5rem 6rem .5rem 1rem;
  border-radius: .3rem;
  border-color: #eee;

}

.rs-picker-toggle-value {
  font-family: 'Poppins', serif;
}

.c-table-filter .u-float-right select {
  border: 1px solid #eee;
  border-radius: .2rem;
  padding: .5rem 1rem;
  font-size: 1.3rem;
  font-family: 'Poppins', serif;
}

.c-table-filter {
  align-items: flex-end;
}

.c-info-blocks__flex {
  display: flex;

  > * {
    margin: 0 1rem 0 0;
    height: auto;
  }
}

.c-rental-sidebar {
  position: fixed;
  width: 30rem;
  top: 0;
  right: -28rem;
  height: 100vh;
  background: #fff;
  padding: 3rem;
  box-shadow: -5px 5px 5px rgba(0, 0, 0, .1);
  overflow: visible;
  transition: all ease-in-out .2s;
  z-index: 3;

  &.is-active {
    right: 0;

    .toggle-button {
      .icon {
        transform: rotate(0deg);
      }
    }
  }

  .toggle-button {
    background: #272727;
    color: #fff;
    height: 5rem;
    width: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .4rem;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -1.5rem;
    transition: all ease-in-out .2s;
    transition-delay: .2s;


    .icon {
      transform: rotate(180deg);
      font-size: 1rem;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      font-size: 1.5rem;

      span {
        display: block;
        font-size: 1.5rem;
        font-weight: bold;
      }

      margin-bottom: 1rem;
    }

  }

  &__qr {
    width: 100%;
    margin-top: 3rem;
  }
}

.c-order-handling__action-bar {
  z-index: 5;
}

.c-modal--qr {

  .c-modal__content {
    width: 50rem;
    padding: 5rem;
    text-align: center;

    h2 {
      display: block;
    }


    img {
      display: block;
      margin: 2rem auto 0;
    }

  }
}

.c-header__logo {
  display: flex;
  align-items: center;

  .c-location-title {
    font-size: 1.5rem;
    margin-left: 1rem;
    font-weight: bold;
  }
}

.c-service-button {
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .3rem .5rem 0;
  border-radius: .3rem;
  background: #fff;
  height: 3rem;

  i {
    margin: 0;
    padding: 0;
  }


  svg {
    height: 2rem;
    fill: #000 !important;
    width: auto;

    path {
      fill: #000;
    }

  }

}

.button-td {
  display: inline-flex;
  align-items: center;

  button {
    display: inline-block;
    margin-bottom: 1rem;
    margin-right: .5rem;
  }
}


.status-service {
  .c-service-button {
    background: #000;
    border-color: #000;

    svg {
      path {
        fill: #fff;
      }
    }

    transform: rotate(180deg);
  }
}

.c-rental-info-bar {
  margin-bottom: 4rem;

  ul {
    display: flex;
    justify-content: space-between;
    background: #eee;
    padding: 2rem;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    li {
      display: block;
      margin: 0 2rem;
      text-align: center;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;

      @media screen and (max-width: 800px) {
        display: flex;
        align-items: flex-start;
        margin: 2.5px 0;
      }

      span {
        display: block;
        font-weight: normal;
        font-size: 1.2rem;

        @media screen and (max-width: 800px) {
          padding-left: 10px;
        }
      }
    }
  }
}

.c-return-late {
  label {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    input {
      margin-right: 1rem;
    }
  }
}

.list-loading-true {
  opacity: .3;
  pointer-events: none;
}

.rs-picker-toolbar-option {
  font-size: 1rem;
}

.c-transaction-filter {
  input[type='text'] {
    border: 1px solid #eee;
    border-radius: .2rem;
    padding: .5rem 1rem;
    font-size: 1.3rem;
    font-family: 'Poppins', serif;
  }
}

.max-td {

  td {
    max-width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 2rem;
  }

}

.hover-td {
  &:hover {
    cursor: pointer;
  }
}

.c-calculator-category {
  margin: 0 2rem;
  display: flex;
  align-items: center;
  font-weight: bold;

  input {
    margin-right: 1rem;
  }
}

.c-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  select, input {
    width: 100%;
    padding: 1rem
  }
}

.c-table-standard {
  tr:hover {
    cursor: pointer;
  }
}


button:disabled {
  opacity: .4 !important;
}

.c-swap-container {
  padding: 2rem;
  margin-top: 2rem;
  border: 1px solid #eee;


  > div {
    opacity: 1;
    transition: .2s ease-in-out all;


  }

}

.is-disabled {
  opacity: .2 !important;
  pointer-events: none !important;
}

.c-processing {
  display: flex;
  align-items: center;
}

.c-swap-container {
  margin-bottom: 2rem;
}

.item-row {
  &.is-warning {
    background: rgb(255, 227, 175);
  }
}

.c-order-handling__list__single.is-complete {
  background: #5cac5c;
  color: #fff;

  button {
    background: none;
  }
}

.c-scan-text {
  small {
    display: block;
  }

  .back-btn {
    margin-top: 5rem;
    display: block;
  }
}

.c-table {
  &--custom {
    margin: 2rem 0;

    th, td {
      font-size: 1.3rem;
    }

    thead {
      tr {
        background: green !important;

        th {
          color: #fff;
          font-weight: normal;

          &.is-sort {
            font-weight: bold;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    tr {
      background: #fff;

      &:hover {
        cursor: pointer;
      }

      td:first-child, th:first-child {
        padding-left: 1rem;
      }


      &:nth-child(2n - 1) {
        background: #eee;
      }
    }
  }
}

.c-part-loader {
  min-height: 20rem;
  display: flex;
  align-items: center;
}

.sort-icon {
  font-size: 1rem;
  display: inline-block;

  &.sort-ASC {

  }

  &.sort-DESC {
    transform: rotate(180deg);
  }
}